"use strict";
/*!
 *
 *  expander.js
 *
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var toggler_1 = require("toggler");
var Expander = /** @class */ (function (_super) {
    __extends(Expander, _super);
    function Expander(elem, option) {
        var _this = _super.call(this, elem, option) || this;
        _this.setHeight();
        return _this;
    }
    Expander.prototype.expand = function (e) {
        if (this.setting.viewport[util.viewport]) {
            e.preventDefault();
            var $this = $(e.currentTarget);
            var $target = this.getTarget($this);
            if ($this.is('[aria-selected="true"]')) {
                $this.attr('aria-selected', 'false');
                $target.attr({
                    'aria-expanded': 'false',
                    'aria-hidden': 'true'
                }).css('max-height', 0);
                // setTimeout(function(){
                // 	$target.css('max-height', 0);
                // }, 0);
                // $target.attr({
                // 	'aria-expanded': 'false',
                // 	'aria-hidden': 'true'
                // }).css('max-height', 0);
            }
            else {
                if (this.setting.autoClose) {
                    this.reset();
                }
                ;
                $this.attr('aria-selected', 'true');
                $target.attr({
                    'aria-expanded': 'true',
                    'aria-hidden': 'false'
                }).css('max-height', $target.data('maxHeight'));
            }
            ;
        }
        ;
    };
    Expander.prototype.reset = function () {
        this.$head.filter(function (i, elem) {
            return $(elem).is('[aria-selected="true"]');
        }).attr('aria-selected', 'false');
        ;
        this.$body.filter(function (i, elem) {
            return $(elem).is('[aria-expanded="true"]');
        }).attr({
            'aria-expanded': 'false',
            'aria-hidden': 'true'
        }).css('max-height', 0);
    };
    Expander.prototype.setHeight = function () {
        this.$body.each(function (i, elem) {
            var h = $(elem).children().outerHeight();
            var maxHeight = h !== undefined ? h : '100%';
            $(elem).data('maxHeight', maxHeight);
        });
    };
    Expander.prototype.setEvent = function () {
        var _this = this;
        _super.prototype.setEvent.call(this);
        $(window).on('resize', function () {
            _this.setHeight();
        });
        $(window).on('viewportChanged', function () {
            _this.reset();
        });
        // this.$body.on('transitionend', (e) => {
        // 	//console.log($(e.currentTarget).css('max-height'))
        // 	if ($(e.currentTarget).css('max-height') !== '0px') {
        // 		$(e.currentTarget).removeAttr('style');
        // 	};
        // });
        // this.$body.each((i, elem) => {
        // 	elem.addEventListener('transitionend', (e) => {
        // 		if (e.propertyName === 'max-height') {
        // 			$(e.currentTarget).removeAttr('style');
        // 		};
        // 	})
        // });
    };
    return Expander;
}(toggler_1.Toggler));
$.fn.expander = function (option) {
    return this.each(function (i, elem) {
        new Expander(elem, option ? option : {});
    });
};
