"use strict";
/*!
 *
 *  hasher.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function () {
    if (util.hash.length > 0) {
        location.href = util.hash + '_';
        if ($(util.hash).length) {
            $(window).on('load', function () {
                var offset = $(util.hash).offset();
                var top = offset !== undefined ? offset.top : 0;
                $(util.scroller).animate({
                    scrollTop: top - util.scrollGap
                }, 5);
                history.replaceState(null, '', util.hash);
            });
        }
        else {
            history.replaceState(null, '', util.hash);
        }
        ;
    }
    ;
});
