/*!
 *
 *  tab.js
 *
 */


const tab = function(_this, opt) {
	this.$nav = $(_this);
	this.setting = $.extend({}, this.default, opt);
	this.hash = util.hash;
	this.init();
};

tab.prototype = {
	init: function() {
		const self = this;
		let contents = [];
		this.$a = this.$nav.find('a');
		// .filter(function(){
		// 	let _href = $(this).attr('href');
		// 	return _href.search(/^#/) > -1 && $(_href).length;
		// });
		this.hasHashCurrent = false;
		this.$a.each(function(i){
			let _href = $(this).attr('href');
			if( _href === util.hash ){
				self.hasHashCurrent = true;
			};
			contents.push( $(this).attr('href') );
		});
		this.$section = $( contents.join() );
		this.$contents = this.$section.parent();
		this.setDefault();
		this.setEvent();
	},
	setDefault: function() {
		const self = this;
		let $link, $contents;

		this.$a.each(function(i){
			let _href = $(this).attr('href');
			if( self.hasHashCurrent ){
				if( _href === util.hash ){
					$link = $(this);
					$contents = $(_href);
				};
			}else {
				if( i === 0 ){
					$link = $(this);
					$contents = $(_href);
				};
			};
			if ($(this).is('.' + self.setting.current)) {
				$link = $(this);
				$contents = $(_href);
				return;
			};
		});
		$link.addClass(this.setting.current);
		$contents.addClass(this.setting.current);
		// if( this.hasHashCurrent ){
		// 	$(window).on('load', function(){
		// 		history.replaceState(null, null, util.hash);
		// 	});
		// };
	},
	setEvent: function() {
		const self = this;
		this.$a.on('click', function(e){
			e.preventDefault();
			if ($(this).is(self.setting.not)) return false;
			if( $(this).is('.'+self.setting.current) ) return false;
			let _href = $(this).attr('href');
			self.$nav.find('.'+self.setting.current).removeClass(self.setting.current);
			self.$contents.children('.'+self.setting.current).removeClass(self.setting.current);
			$(this).addClass(self.setting.current);
			$( _href ).addClass(self.setting.current);
			//history.pushState(null, null, _href);
		});

		$(window).on('popstate', function(){
			let _hash = location.hash;
			self.$a.each(function(){
				let _href = $(this).attr('href');
				if( _href === _hash ){
					$(this).addClass(self.setting.current);
					$(_href).addClass(self.setting.current);
				}else {
					$(this).removeClass(self.setting.current);
					$(_href).removeClass(self.setting.current);
				};
			});
		});
	},
	default: {
		current: 'current',
		not: '.not'
	}
};

$.fn.tab = function( options ){
	const opt = options ? options : {};
	this.each(function(){
		new tab( this, opt );
	});
};