"use strict";
/*!
 *
 *  menu.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var Menu = /** @class */ (function () {
    function Menu(elem) {
        this.$btn = $(elem);
        var target = this.$btn.attr('href') !== undefined ? this.$btn.attr('href') : 'body';
        this.$target = $(target);
    }
    return Menu;
}());
var BuildMenu = /** @class */ (function () {
    function BuildMenu(elems) {
        var _this = this;
        this.$overlay = $('#Overlay');
        this.$close = $('#OverlayClose');
        this.menu = [];
        this.$btns = $(elems);
        this.$btns.each(function (i, elem) {
            _this.menu.push(new Menu(elem));
        });
        this.setEvent();
    }
    BuildMenu.prototype.setEvent = function () {
        var _this = this;
        this.menu.forEach(function (menu, i) {
            menu.$btn.on('click', function (e) {
                _this.open(menu, e);
            });
        });
        this.$close.on('click', function (e) {
            _this.close(e);
        });
    };
    BuildMenu.prototype.open = function (menu, e) {
        e.preventDefault();
        menu.$btn.toggleAria('selected');
        this.$overlay.toggleAria('hidden');
        menu.$target.toggleAria('hidden');
        this.current = {
            $btn: menu.$btn,
            $target: menu.$target
        };
    };
    BuildMenu.prototype.close = function (e) {
        e.preventDefault();
        this.current.$btn.toggleAria('selected');
        this.$overlay.toggleAria('hidden');
        this.current.$target.toggleAria('hidden');
    };
    return BuildMenu;
}());
exports.BuildMenu = BuildMenu;
