/*!
 *
 *  load-image.js
 *
 */

module.exports = function( src ){
	const d = new $.Deferred;
	const $img = $('<img />');
	let _src = src + '?' + new Date().getTime();
	$img.attr('src', _src).on('load', function(){
		d.resolve();
	});
	return d.promise();
};

