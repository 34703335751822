"use strict";
/*!
 *
 *  stickyer.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(option) {
        var _this = this;
        this.isOver = false;
        this.setting = {
            fixedElem: '#Header',
            limitElem: undefined,
            elemClass: undefined,
            stateClass: 'fixed'
        };
        $.extend(true, this.setting, option ? option : {});
        this.$fixedElem = $(this.setting.fixedElem);
        this.$limitElem = this.setting.limitElem ? $(this.setting.limitElem) : undefined;
        this.limit = this.setLimit();
        this.stateClass = this.setting.elemClass !== undefined ? this.setting.elemClass + '-' + this.setting.stateClass : 'is-' + this.setting.stateClass;
        $(window).on('scroll', function (e) {
            _this.scroll(e);
        });
        $(window).on('viewportChanged', function () {
            _this.limit = _this.setLimit();
        });
    }
    default_1.prototype.setLimit = function () {
        if (util.viewport !== 'mobile') {
            if (this.$limitElem) {
                var offset = this.$limitElem.offset();
                return offset ? offset.top : 0;
            }
            else {
                return 0;
            }
        }
        else {
            return 0;
        }
        ;
    };
    default_1.prototype.scroll = function (e) {
        var top = $(e.currentTarget).scrollTop();
        top = top !== undefined ? top : 0;
        if (this.limit < top && !this.isOver) {
            this.isOver = true;
            this.$fixedElem.addClass(this.stateClass);
        }
        ;
        if (this.limit >= top && this.isOver) {
            this.isOver = false;
            this.$fixedElem.removeClass(this.stateClass);
        }
        ;
    };
    return default_1;
}());
exports.default = default_1;
