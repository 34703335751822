"use strict";
/*!
 *
 *  nav-current.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(elem, option) {
        this.setting = {
            currentClass: 'is-current',
            abs: false,
            not: undefined,
            root: '/',
            escape: [],
            add: []
        };
        this.URLPath = location.pathname + location.search;
        this.URLLocate = location.protocol + '//' + location.hostname;
        $.extend(this.setting, option);
        this.$elem = $(elem);
        this.setCurrent();
    }
    default_1.prototype.setCurrent = function () {
        var _this = this;
        this.$elem.find('a').each(function (i, elem) {
            if (_this.setting.not !== undefined && $(elem).is(_this.setting.not)) {
                return;
            }
            ;
            var href = $(elem).attr('href');
            href = href !== undefined ? href.replace(_this.URLLocate, '') : undefined;
            var escape = (function () {
                var isMatch = false;
                _this.setting.escape.forEach(function (val, i) {
                    if (href === val) {
                        isMatch = true;
                    }
                    ;
                });
                return isMatch;
            })();
            if (href !== undefined) {
                var isCurrent = (_this.URLPath.search(href) == 0);
                if (_this.setting.abs || href === _this.setting.root) {
                    isCurrent = (_this.URLPath === href);
                }
                ;
                if (isCurrent) {
                    //$(elem).addClass(this.setting.currentClass);
                    $(elem).attr('aria-current', 'true');
                    if (!escape) {
                        if ($(elem).closest('.st-LocalList_Item').length) {
                            $(elem).closest('.st-LocalList_Item').children('.st-LocalList_Link').attr('aria-current', 'true');
                        }
                        ;
                        if ($(elem).closest('.st-LocalList2_Item').length) {
                            $(elem).closest('.st-LocalList2_Item').children('.st-LocalList2_Link').attr('aria-current', 'true');
                        }
                        ;
                    }
                    ;
                }
                else {
                    $(elem).removeClass(_this.setting.currentClass);
                }
                if (_this.setting.abs) {
                    if (href === '/english/about/') {
                    }
                    else {
                        var _URL_1 = _this.URLPath.replace(/(.+\/)(.+\.html)$/g, '$1');
                        _this.setting.add.forEach(function (val, i) {
                            if (_URL_1.search(val) != -1) {
                                _URL_1 = val;
                            }
                            ;
                        });
                        if (href === _URL_1) {
                            //$(elem).addClass(this.setting.currentClass);
                            if (escape) {
                                if (!$(elem).is('.st-LocalList2_Link')) {
                                    $(elem).attr('aria-current', 'true');
                                }
                                ;
                            }
                            else {
                                $(elem).attr('aria-current', 'true');
                                if ($(elem).closest('.st-LocalList_Item').length) {
                                    //$(elem).closest('.st-LocalList_Item').children('.st-LocalList_Link').addClass(this.setting.currentClass);
                                    $(elem).closest('.st-LocalList_Item').children('.st-LocalList_Link').attr('aria-current', 'true');
                                }
                                ;
                                if ($(elem).closest('.st-LocalList2_Item').length) {
                                    //$(elem).closest('.st-LocalList2_Item').children('.st-LocalList2_Link').addClass(this.setting.currentClass);
                                    $(elem).closest('.st-LocalList2_Item').children('.st-LocalList2_Link').attr('aria-current', 'true');
                                }
                                ;
                            }
                            ;
                        }
                        else {
                            var _url = _URL_1.split('/');
                            if (_url.length > 6) {
                                _url.pop();
                                _url.pop();
                                var __url = _url.join('/') + '/';
                                if (href === __url) {
                                    $(elem).addClass(_this.setting.currentClass);
                                    if ($(elem).closest('.st-LocalList_Item').length) {
                                        //$(elem).closest('.st-LocalList_Item').children('.st-LocalList_Link').addClass(this.setting.currentClass);
                                        $(elem).closest('.st-LocalList_Item').children('.st-LocalList_Link').attr('aria-current', 'true');
                                    }
                                    ;
                                    if ($(elem).closest('.st-LocalList2_Item').length) {
                                        //$(elem).closest('.st-LocalList2_Item').children('.st-LocalList2_Link').addClass(this.setting.currentClass);
                                        $(elem).closest('.st-LocalList2_Item').children('.st-LocalList2_Link').attr('aria-current', 'true');
                                    }
                                    ;
                                }
                                ;
                            }
                            ;
                        }
                    }
                }
            }
            ;
        });
    };
    return default_1;
}());
exports.default = default_1;
