"use strict";
/*!
 *
 *  local-menu.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(elem) {
        var _this = this;
        this.head = '.st-Local_Btn';
        this.body = '.st-Local_Body';
        this.sptitle = '.st-Local_Title_Link-facility';
        this.$elem = $(elem);
        if (util.viewport === 'desktop' || util.viewport === 'largeDesktop') {
            this.$elem.find(this.head).toggleAria('selected');
            this.$elem.find(this.body).toggleAria('expanded');
        }
        ;
        this.$clone = this.getClone();
        $('#Contents').prepend(this.$clone);
        this.setEvent(this.$elem);
        this.setEvent(this.$clone);
        $(window).on('viewportChanged', function () {
            if (util.viewport === 'desktop' || util.viewport === 'largeDesktop') {
                _this.$elem.find(_this.head).attr('aria-selected', 'true');
                _this.$elem.find(_this.body).attr('aria-expanded', 'true');
            }
            else {
                _this.$elem.find(_this.head).attr('aria-selected', 'false');
                _this.$elem.find(_this.body).attr('aria-expanded', 'false');
            }
        });
    }
    default_1.prototype.getClone = function () {
        var _this = this;
        var $clone = this.$elem.clone(false);
        var $ids = $clone.find('[id]');
        $ids.each(function (i, elem) {
            $(elem).attr('id', _this.getCloneID($(elem).attr('id')));
        });
        var cl = $clone.attr('class') + '-cloned';
        $clone.attr('id', this.getCloneID($clone.attr('id'))).addClass(cl);
        var href = $clone.find(this.head).attr('href');
        $clone.find(this.head).attr('href', this.getCloneID(href));
        return $clone;
    };
    default_1.prototype.getCloneID = function (id) {
        return id + 'Clone';
    };
    default_1.prototype.setEvent = function ($elem) {
        $elem.expander({
            head: this.head,
            body: this.body,
            viewport: {
                desktop: false,
                largeDesktop: false
            }
        });
        $elem.find(this.sptitle).on('click', function (e) {
            if (util.viewport !== 'desktop' && util.viewport !== 'largeDesktop') {
                e.preventDefault();
                var $btn = $(e.currentTarget).closest('.st-Local_Title').next('.st-Local_Btn');
                $btn.trigger('click');
            }
            ;
        });
    };
    return default_1;
}());
exports.default = default_1;
