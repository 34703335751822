/*!
 *
 *  fit-image.js
 *
 */

const defo = {
	isHalf: false,
	exclusion: null
};

let setting = {};

const FitImage = function(_this, opt) {
	this.$img = $(_this);
	this.init();
};

FitImage.prototype = {
	init: function() {
		this.setSize();
	},
	setSize: function() {
		var self = this,
			_img = $('<img />'),
			_src = this.$img.attr('src') + "?" + new Date().getTime();

		var _w = this.$img.attr('width');

		_img.attr('src', _src).on('load', function() {
			if( _w == undefined ){
				_w = $(this).prop('width');
				if( setting.isHalf ){
					_w = _w / 2;
				};
			};

			_w = parseInt(_w);

			if (_w != 0) {
				self.$img.css({
					'width': '100%',
					'height': 'auto',
					'max-width': _w
				});
			};
			origin.$window.trigger('resize');
		});
	}
};

$.fn.fitImages = function(options) {
	const opt = options ? options : {};
	setting = $.extend({}, defo, opt);
	this.each(function() {
		if( !$(this).is(setting.exclusion) ){
			new FitImage(this, opt);
		};
	});
};