"use strict";
/*!
 *
 *  lazy-contents.ts
 *
 */
var LazyContents = /** @class */ (function () {
    function LazyContents(elem, key) {
        var _this = this;
        this.$elem = $(elem);
        this.top = this.getTop();
        this.key = '.lazy' + key.toString();
        $(window).on('scroll' + this.key, function (e) {
            //console.log('scroll')
            var top = $(e.currentTarget).scrollTop() + (util.wh / 1.5);
            if (top > _this.top) {
                $(window).off(_this.key);
                _this.$elem.toggleAria('hidden');
            }
            ;
        });
        $(window).on('load CSSLoad resize', function (e) {
            _this.top = _this.getTop();
        });
    }
    LazyContents.prototype.getTop = function () {
        var offset = this.$elem.offset();
        if (offset !== undefined) {
            return offset.top;
        }
        else {
            return 0;
        }
        ;
    };
    return LazyContents;
}());
$.fn.lazyContents = function () {
    return this.each(function (i, elem) {
        new LazyContents(elem, i);
    });
};
