"use strict";
/*!
 *
 *  top.ts
 *
 */
module.exports = function () {
    if ($('#TopMain').length) {
        $('#PickupSlider').slick({
            slidesToShow: 3,
            prevArrow: '<div class="tp-PickupSlider_Arrow"><button type="button" class="slick-prev" aria-label="前へ"></button></div>',
            nextArrow: '<div class="tp-PickupSlider_Arrow"><button type="button" class="slick-next" aria-label="次へ"></button></div>',
            arrows: true,
            dots: true,
            appendDots: $('#PickupDots'),
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                }, {
                    breakpoint: 414,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
        $('#FeatureSlider').slick({
            slidesToShow: 3,
            prevArrow: '<div class="tp-FeatureSlider_Arrow"><button type="button" class="slick-prev" aria-label="前へ"></button></div>',
            nextArrow: '<div class="tp-FeatureSlider_Arrow"><button type="button" class="slick-next" aria-label="次へ"></button></div>',
            arrows: true,
            dots: true,
            appendDots: $('#FeatureDots'),
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                }, {
                    breakpoint: 567,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
        $('#BnrSlider').slick({
            slidesToShow: 4,
            prevArrow: '<div class="tp-Bnr_Arrow"><button type="button" class="slick-prev" aria-label="前へ"></button></div>',
            nextArrow: '<div class="tp-Bnr_Arrow"><button type="button" class="slick-next" aria-label="次へ"></button></div>',
            arrows: true,
            dots: true,
            autoplay: true,
            appendDots: $('#BnrDots'),
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3
                    }
                }, {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                }, {
                    breakpoint: 414,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
        $('.sw-Lazy').lazyContents();
        $('#Hero').gallery();
    }
    ;
};
